import { useEffect, useState } from 'react';
import Logger from '@util/Logger';

const logger = Logger.make('hooks/useMediaQuery');

/**
 * Custom hook that tells you whether a given media query is active.
 *
 * Inspired by https://usehooks.com/useMedia/
 * https://gist.github.com/gragland/ed8cac563f5df71d78f4a1fefa8c5633
 */
export default function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
        // Some versions of browsers dont have the addEventListener function and need to fall back to the older, deprecated addListener method
        try {
            const mediaQuery = window.matchMedia(query);
            if (!mediaQuery) {
                logger.error(new Error('Media query is not defined'));
                return;
            }
            setMatches(mediaQuery?.matches ?? false);
            const handler = (event) => setMatches(event?.matches ?? false);
            if (mediaQuery.addEventListener) {
                mediaQuery.addEventListener('change', handler);
                return () => mediaQuery.removeEventListener?.('change', handler);
            } else {
                mediaQuery.addListener(handler);
                return () => mediaQuery.removeListener(handler);
            }
        } catch (error) {
            logger.error(error);
        }
    }, []);
    return matches;
}
