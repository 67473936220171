import useMediaQuery from '@hooks/useMediaQuery';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type BreakpointStatus = {
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    is2xl: boolean;
    active: Breakpoint;
};

export default function useBreakpoints() {
    const breakpoints: BreakpointStatus = {
        isXs: useMediaQuery('(max-width: 639px)'),
        isSm: useMediaQuery('(min-width: 640px)'),
        isMd: useMediaQuery('(min-width: 768px)'),
        isLg: useMediaQuery('(min-width: 1024px)'),
        isXl: useMediaQuery('(min-width: 1280px)'),
        is2xl: useMediaQuery('(min-width: 1536px)'),
        active: 'xs',
    };
    if (!breakpoints.isXs) breakpoints.active = 'xs';
    if (breakpoints.isSm && !breakpoints.isMd) breakpoints.active = 'sm';
    if (breakpoints.isMd && !breakpoints.isLg) breakpoints.active = 'md';
    if (breakpoints.isLg && !breakpoints.isXl) breakpoints.active = 'lg';
    if (breakpoints.isXl && !breakpoints.is2xl) breakpoints.active = 'xl';
    if (breakpoints.is2xl) breakpoints.active = '2xl';
    return {
        ...breakpoints,
        isSmAndDown: ['xs', 'sm'].includes(breakpoints.active),
        isMdAndDown: ['xs', 'sm', 'md'].includes(breakpoints.active),
        isLgAndDown: ['xs', 'sm', 'md', 'lg'].includes(breakpoints.active),
        isXlAndDown: ['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoints.active),
        is2xlAndDown: ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(breakpoints.active),
    };
}
