import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import cn from 'classnames';

type Direction = 'left' | 'right';
type Props = {
    children?: ReactNode;
    open: boolean;
    setOpen: (open: boolean) => void;
    noPadding?: boolean;
    className?: string;
    direction?: Direction;
    allScreenSizes?: boolean;
};
/**
 * Note: the sidebar will close instantly instead of animating out to fix a bug with the
 * Dialog component that causes the page to be unresponsive after th dialog animates out.
 * See the [Github Issue](https://github.com/tailwindlabs/headlessui/issues/1705) for more details
 */
const MobileSidebar = ({
    children,
    open,
    setOpen,
    noPadding = false,
    className,
    direction = 'left',
    allScreenSizes = false,
}: Props) => {
    const isLeft = direction === 'left';
    const isRight = direction === 'right';
    return (
        <Transition show={open} as={Fragment}>
            <Dialog
                className={cn('fixed inset-0 z-50 flex ', {
                    'flex-row-reverse': isRight,
                    'lg:hidden': !allScreenSizes,
                })}
                open={open}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300"
                    enterFrom={isLeft ? '-translate-x-full' : 'translate-x-full'}
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300"
                    leaveFrom={isLeft ? 'translate-x-0' : 'translate-x-full'}
                    leaveTo={isLeft ? '-translate-x-full' : 'translate-x-full'}
                >
                    <Dialog.Panel className={cn('relative flex w-full max-w-sm flex-1 flex-col bg-white ')}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div
                                className={cn('absolute top-0 pt-2', {
                                    'right-0 -mr-12': isLeft,
                                    'left-0 -ml-12': isRight,
                                })}
                            >
                                <button
                                    type="button"
                                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className={cn('h-full overflow-scroll', className, { 'pb-8 pt-4': !noPadding })}>
                            {children}
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 shrink-0" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
            </Dialog>
        </Transition>
    );
};

export default MobileSidebar;
