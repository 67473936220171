import Link, { LinkProps } from 'next/link';
import Button, { LoadableButtonProps } from '@components/Button';
import { HTMLAttributeAnchorTarget } from 'react';

type HTMLLinkProps = Partial<Pick<HTMLAnchorElement, 'rel' | 'target' | 'download'>>;
type LinkButtonProps = LoadableButtonProps;
type NextLinkProps = Omit<LinkButtonProps, 'href'> &
    Pick<LinkProps, 'href' | 'as' | 'prefetch' | 'locale'> & { target?: HTMLAttributeAnchorTarget } & HTMLLinkProps;
export const LinkButton = ({
    href,
    as,
    prefetch,
    locale,
    children,
    variant = 'contained',
    color = 'primary',
    ...props
}: NextLinkProps) => {
    return (
        <Link href={href} as={as} prefetch={prefetch} locale={locale} passHref legacyBehavior>
            <Button {...props} color={color} variant={variant}>
                {children}
            </Button>
        </Link>
    );
};

export default LinkButton;
