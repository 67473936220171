import Button from '@components/Button';
import { useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import cn from 'classnames';
import Link from 'next/link';
import { DropdownLinksButton } from '@components/DropdownLinksButton';
import MobileSidebar from '@components/MobileSidebar';
import { useTheme } from '@contexts/ThemeContext';
import { MenuIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import useAuth from '@hooks/useAuth';

const NavItems = ({ closeMenu }: { closeMenu: () => void }) => {
    const router = useRouter();
    const { isLoggedIn } = useAuth();
    const isDark = router.pathname.includes('get-info');

    const linkClasses = cn(
        'py-4 text-xl font-semibold text-white hover:text-indigo-300 sm:mt-0 sm:p-3 sm:text-sm cursor-pointer hover:opacity-70',
        {
            'text-white': isDark,
            'sm:text-gray-800 sm:hover:text-indigo-700': !isDark,
        },
    );

    const goToLabspace = () => {
        if (!!isLoggedIn) return (window.location.href = '/labspace');
        router.replace('/login');
    };

    return (
        <>
            <DropdownLinksButton
                buttonText="Product"
                items={[
                    {
                        label: 'Overview',
                        href: 'https://pluto.bio/product',
                        onClick: () => closeMenu(),
                    },
                    {
                        label: 'Assays & Analyses',
                        href: 'https://pluto.bio/product/assays',
                        onClick: () => closeMenu(),
                    },
                    {
                        label: 'Data Visualization',
                        href: 'https://pluto.bio/product/visualizations',
                        onClick: () => closeMenu(),
                    },
                    { label: 'Security', href: 'https://pluto.bio/product/security', onClick: () => closeMenu() },
                    {
                        label: 'Integrations',
                        href: 'https://pluto.bio/product/integrations',
                        onClick: () => closeMenu(),
                    },
                ]}
            />
            <Link href="https://pluto.bio/pricing" passHref legacyBehavior>
                <div className={linkClasses} onClick={() => closeMenu()}>
                    Pricing
                </div>
            </Link>
            <DropdownLinksButton
                buttonText="Solutions"
                items={[
                    {
                        label: 'Academia',
                        href: 'https://pluto.bio/solutions/academia',
                        onClick: () => closeMenu(),
                    },
                    {
                        label: 'Biotech & Pharma',
                        href: 'https://pluto.bio/solutions/pharma',
                        onClick: () => closeMenu(),
                    },
                    {
                        label: 'Service providers',
                        href: 'https://pluto.bio/solutions/service-providers',
                        onClick: () => closeMenu(),
                    },
                    {
                        label: 'All solutions',
                        href: 'https://pluto.bio/solutions/',
                        onClick: () => closeMenu(),
                    },
                ]}
            />
            <DropdownLinksButton
                buttonText="Company"
                className="mr-2"
                items={[
                    { label: 'About', href: 'https://pluto.bio/about', onClick: () => closeMenu() },
                    {
                        label: 'Blog',
                        href: 'https://pluto.bio/resources',
                        onClick: () => closeMenu(),
                    },
                    { label: 'News', href: 'https://pluto.bio/resources/news', onClick: () => closeMenu() },
                ]}
            />

            <span className="flex items-center justify-center">
                <Link href="https://pluto.bio/get-info" legacyBehavior>
                    <Button
                        variant="contained"
                        color={isDark ? 'secondary' : 'primary'}
                        data-cy="getstarted-btn"
                        size="small"
                        textClass="tracking-tight"
                    >
                        Get started
                    </Button>
                </Link>
                <Button
                    variant="outlined"
                    color={isDark ? 'white' : 'primary'}
                    size="small"
                    loading={false}
                    data-cy="labspace-btn"
                    onClick={goToLabspace}
                    className="ml-2"
                >
                    Lab Space
                </Button>
            </span>
        </>
    );
};

export type HeaderProps = {
    showMenu?: boolean;
    sticky?: boolean;
    dark?: boolean;
};

export const NavBar = ({ showMenu = true, sticky = true }: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const small = useMediaQuery('(min-width:640px) and (max-width:767px)');

    const { theme } = useTheme();
    const { dark } = theme;

    const closeMenu = () => setMenuOpen(false);

    return (
        <>
            <div id="top" />
            <header
                className={cn('relative top-0 z-30 px-5 py-2 backdrop-blur-lg transition sm:p-6 md:py-4', {
                    'sticky bg-opacity-60 firefox:bg-opacity-95': sticky,
                    'bg-gray-800': dark,
                    'bg-white': !dark,
                })}
            >
                <div className="mx-auto flex max-w-screen-2xl items-center justify-between">
                    <Link
                        href="https://pluto.bio"
                        passHref
                        legacyBehavior
                        className="cursor-pointer text-primary hover:opacity-70"
                        aria-label="Pluto home page"
                    >
                        <img
                            src="https://cdn.bfldr.com/9AK17BV1/at/wkvwmg2jt85z4xg6f52wnw/Pluto_Logo?width=180"
                            alt="Pluto Bio"
                            className="cursor-pointer"
                        />
                    </Link>
                    {showMenu && !small && (
                        <IconButton
                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                            size="small"
                            onClick={() => setMenuOpen(true)}
                            aria-label={menuOpen ? 'Close navigation menu' : 'Open navigation menu'}
                        >
                            <span className="relative z-20 text-indigo-500 sm:hidden">
                                <MenuIcon width={24} className="text-primary dark:text-white" />
                            </span>
                        </IconButton>
                    )}
                    <nav
                        className={cn(
                            'absolute bottom-0 left-0 right-0 top-0 z-10 hidden h-screen w-full flex-col bg-indigo-600 p-6 pb-24 text-base tracking-tight sm:static sm:flex sm:h-auto sm:w-auto sm:flex-row sm:bg-transparent sm:p-0',
                        )}
                    >
                        <NavItems closeMenu={closeMenu} />
                    </nav>
                    <MobileSidebar
                        open={menuOpen}
                        setOpen={setMenuOpen}
                        noPadding
                        className="bg-indigo-600 px-8 pb-28"
                        direction="right"
                    >
                        <div className="flex flex-col">
                            <NavItems closeMenu={closeMenu} />
                        </div>
                    </MobileSidebar>
                </div>
            </header>
        </>
    );
};

export default NavBar;
