import { useRouter } from 'next/router';
import { HTMLAttributeAnchorTarget, MouseEvent, useState } from 'react';
import Link from 'next/link';
import { List, ListItem, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useBreakpoints from '@hooks/useBreakpoints';
import { useTheme } from '@contexts/ThemeContext';
import cn from 'classnames';
import { ThemeProvider } from '@mui/material/styles';

type LinkItem = {
    label: string;
    href: string;
    external?: boolean;
    target?: HTMLAttributeAnchorTarget;
    onClick?: () => void;
};
type DropdownLinksButtonProps = {
    buttonText: string;
    className?: string;
    items: LinkItem[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};
export const DropdownLinksButton = ({
    buttonText,
    items,
    className,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
}: DropdownLinksButtonProps) => {
    const router = useRouter();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const breakpoints = useBreakpoints();
    const { theme } = useTheme();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={cn(className, 'inline-block')}>
                <span
                    onClick={handleClick}
                    className={cn(
                        'flex cursor-pointer items-center pt-4 text-xl font-semibold text-white hover:text-indigo-300 sm:p-3 sm:pr-2 sm:text-sm',
                        { 'sm:text-gray-800 sm:hover:text-indigo-700': !theme.dark },
                    )}
                >
                    <span>{buttonText}</span>
                    <ChevronDownIcon className="hidden h-4 w-4 sm:inline-block" />
                </span>
                {!breakpoints.isXs && (
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        keepMounted
                        onClose={handleClose}
                        anchorOrigin={anchorOrigin}
                        transformOrigin={transformOrigin}
                    >
                        {items.map((item) => {
                            if (item.external) {
                                return (
                                    <div key={item.label}>
                                        <MenuItem
                                            component="a"
                                            href={item.href}
                                            target={item.target}
                                            onClick={item.onClick}
                                        >
                                            <span className="min-w-[75px] text-sm font-semibold text-default">
                                                {item.label}
                                            </span>
                                        </MenuItem>
                                    </div>
                                );
                            }
                            return (
                                <div key={item.label}>
                                    <Link href={item.href} passHref legacyBehavior>
                                        <MenuItem
                                            component="a"
                                            target={item.target}
                                            selected={router.asPath === item.href}
                                            onClick={item.onClick}
                                        >
                                            <span className="min-w-[75px] text-sm font-semibold text-default ">
                                                {item.label}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                </div>
                            );
                        })}
                    </Menu>
                )}
                {breakpoints.isXs && (
                    <List className="text-white sm:hidden" component="nav">
                        {items.map((item) => {
                            if (item.external) {
                                return (
                                    <div key={item.label}>
                                        <ListItem
                                            component="a"
                                            target={item.target}
                                            href={item.href}
                                            onClick={item.onClick}
                                        >
                                            <span className="text-white">{item.label}</span>
                                        </ListItem>
                                    </div>
                                );
                            }

                            return (
                                <div key={item.label}>
                                    <Link href={item.href} passHref key={item.label} legacyBehavior>
                                        <ListItem component="a" target={item.target} onClick={item.onClick}>
                                            <span className="text-white">{item.label}</span>
                                        </ListItem>
                                    </Link>
                                </div>
                            );
                        })}
                    </List>
                )}
            </div>
        </ThemeProvider>
    );
};
